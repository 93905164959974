<template>
  <base-section id="about-us-detail">
    <div
      data-aos="fade-up"
      data-aos-delay="50"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false"
      data-aos-anchor-placement="top-center"
    >
      <base-section-heading
        color="grey lighten-2"
        title="Sejarah Orion Penjaminan Indonesia"
      />
      <v-container class="text-justify">
        <p>
          <span style="font-weight: 400"
            >PT. Orion Penjaminan Indonesia didirikan pada tanggal 12 April
            tahun 2023 dengan Akta Pendirian No. 5 yang dibuat dihadapan Arief
            Yulianto,S.H., M. Kn Notaris di Jakarta dan telah mendapat
            pengesahan dari Menteri Kehakiman RI tanggal 12 April 2023 Nomor:
            AHU- 0028748.AH.01.01.TAHUN 2023. Anggaran Dasar Perusahaan telah
            mengalami perubahan. Perubahan anggaran dasar terakhir diubah dengan
            Akta Perubahan Nomor: 09 tanggal 17 Mei 2023, yang dibuat oleh Arief
            Yulianto,S.H., M. Kn Notaris di Jakarta dan telah mendapat
            persetujuan dari Menteri Hukum dan HAM RI dengan Surat Keputusan
            Tanggal 17 Mei 2023 Nomor: AHU- AH.01.09-0118229</span
          >
        </p>
        <p>
          <span style="font-weight: 400"
            >Modal disetor pada saat pendirian sebesar Rp 150.000.000.000,‐
            Seratus lima puluh miliar rupiah, dengan Pemegang Saham Pengendali
            yaitu PT. Orion Sedaya Indonesia. Saat ini grup orion terbesar
            beroperasi dalam bidang usaha pembangunan, jasa, perdagangan,
            perindustrian dan investasi melalui anak perusahaannya PT. City
            Retail Development Tbk (NIRO) yang pada September tahun 2023
            mencatat total asset sebesar 13,6 Triliun.</span
          >
        </p>
        <!-- <p>
          <v-img
            :src="require('@/assets/flow-penjaminan.png')"
            max-width="100%"
          />
        </p>
        <p>
          <span style="font-weight: 400"
            >Proses Penjaminan melibatkan sekurang-kurangnya tiga pihak, yaitu
            Penerima Jaminan (Kreditur/Obligee), Terjamin (Debitur/Principal)
            dan Penjamin (PT ORION PENJAMINAN INDONESIA) Melalui layanan
            penjaminan, diharapkan lebih banyak pelaku usaha yang dapat
            mengembangkan bisnisnya melalui aksesibilitas terhadap fasilitas
            perbankan/non perbankan dan meningkatkan kepercayaan pemberi kerja
            atas jasa yang diberikan oleh pelaku usaha.</span
          >
        </p> -->
      </v-container>
    </div>
    <!-- <div 
    data-aos="zoom-in"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="false"
    data-aos-anchor-placement="top-center"
    class="grey lighten-4">
    <v-container>
    <template>
      	  <base-section-heading
	      title="Timeline Takaful Umum"
	    />
		  <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
		    <v-timeline-item
		      v-for="(item, i) in items"
		      :key="i"
		      :color="item.color"
		      :icon="item.icon"
		      fill-dot
		    >
		      <v-card
		        :color="item.color"
		        dark
            outlined
		      >
		        <v-card-title class="title">{{ item.title }}</v-card-title>
		        <v-card-text class="white text--primary body-2">
		          <p>{{ item.text }}</p>
		          <v-btn
		            :color="item.color"
		            class="mx-0"
		            outlined
		          >
		            {{ item.date }}
		          </v-btn>
		        </v-card-text>
		      </v-card>
		    </v-timeline-item>
		  </v-timeline>
		</template>
    </v-container>
  </div>
  <div 
    data-aos="flip-down"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="false"
    data-aos-anchor-placement="top-center">
  <base-section-heading
      color="grey lighten-2"
      title="Sejarah Logo dan Warna"
    />
  <v-container>
    <v-img 
        :src="require('../../../src/assets/logohistory.jpg')"
        max-width="100%"/>
      <base-title
        size="heading"
        space="4"
        title="MAKNA LOGO"
        class="fourth--text"
      />
      <base-body class="text-justify">
      Logo Takaful terdiri dari logotype dan Simbol yang merupakan ciri khas perusahaan. Kata Takaful merupakan Brand Name perusahaan yang mudah diucapkan dan diingat. Revitalisasi logo Takaful ini ingin memposisikan kembali symbol & logotype Takaful sebagai satu kesatuan logo/merk dagang PT Syarikat Takaful Indonesia dan anak perusahaan yaitu PT Orion Penjaminan Indonesia dan PT Asuransi Takaful Keluarga.<br><br>

      Simbol dibentuk dari kata Takaful Dengan menggunakan seni tulis kaligrafi Arab. Simbol disusun oleh lingkaran yang dilindungi oleh dua lengkungan pada bagian atas dan bawah. Lingkaran luar dan lingkaran dalam menandakan perlindungan dan suatu kewaspadaan terhadap kejadian yang tidak diinginkan. Dilingkaran dalam terdapat penulisan Takaful dalam bentuk kaligrafi Arab dengan bentuk dasar Kufik melambangkan perusahaan yang secara Matematik penuh perhitungan. Kaligrafi yang simetris mencerminkan perhitungan teliti dan langkah yang terukur.

      <blockquote class="blockquote">Dilingkaran dalam terdapat penulisan Takaful dalam bentuk kaligrafi Arab dengan bentuk dasar Kufik melambangkan perusahaan yang secara Matematik penuh perhitungan.</blockquote> 

      Takaful Umum dan General Insurance disebut logotype merupakan bagian dari logo. Pemilihan bentuk Takaful didasarkan pada pertimbangan estetis yang memberikan kesan kuat, nyaman, merangkul & melindungi.
      </base-body>
      <base-title
        size="heading"
        space="4"
        title="MAKNA WARNA"
        class="fourth--text"
      />
      <base-body class="text-justify">
      Terdapat 3 warna yang menjadi identitas Takaful, yaitu Biru, Turquoise dan Orange. Warna Biru melambangkan profesionalitas/keahlian dan stabilitas kinerja perusahaan, integritas dan ketekunan. Warna Turquoise melambangkan pertumbuhan keamanan dari asuransi. Sedangkan Orange melambangkan kejayaan
      </base-body>
      <v-tooltip bottom 
        v-for="name in colour"
        :key="name">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
        v-bind="attrs"
        v-on="on"
        :color="name"
        class="ml-2"
        size="48"
      >
      </v-avatar>
      </template>
        {{name}}
    </v-tooltip>
  </v-container>
  </div> -->
  </base-section>
</template>

<script>
export default {
  name: "SectionAboutUsDetail",

  data: () => ({
    colour: ["#001F48", "#008493", "#3EC2D1", "#FF6600"],
    items: [
      {
        color: "primary",
        icon: "mdi-star",
        title: "Syarikat Takaful Indonesia",
        text: "PT Syarikat Takaful lndonesia (Takaful lndonesia) didirikan",
        date: "24 Februari 1994",
      },
      {
        color: "secondary",
        icon: "mdi-book-variant",
        title: "Takaful Umum",
        text: "PT. Orion Penjaminan Indonesia didirikan",
        date: "2 Juni 1995",
      },
      {
        color: "accent",
        icon: "mdi-airballoon",
        title: "Takaful Umum Run Off",
        text: "PT. Orion Penjaminan Indonesia berhenti melakukan kegiatan penjualan dikarenakan pemegang saham STMB tidak melanjutkan",
        date: "9 November 2016",
      },
      {
        color: "fourth",
        icon: "mdi-cash-multiple",
        title: "Kospin Jasa",
        text: "Kospin Jasa mengakuisisi kepemilikkan Takaful Umum dari STMB",
        date: "Maret 2018",
      },
      {
        color: "primary",
        icon: "mdi-gift",
        title: "Underwriting Surplus",
        text: "Takaful Umum membagikan Underwriting Surplus dengan total 3 Miliar Rupiah kepada peserta",
        date: "13 September 2018",
      },
    ],
  }),
};
</script>
